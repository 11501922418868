body {
  margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

h1  {
  font-size: 3rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.35rem;
}

h4 {
  font-size: 0.95rem;
  line-height: 1.4em;
}

h5 {
  font-weight: 500;
}

p {
  font-size: 0.95rem;
}

h1, h2, h3, h4, h5, p {
  margin: 1rem;
  max-width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}